const gridSize = 50;
const gridHalf = gridSize / 2;
const gridMargin = 3;

const thSize = 4;
const thHalf = 2;

const borderAt = gridHalf - gridMargin;
const borderRange = borderAt * 2;
const maxThOffset = borderAt - thHalf;

const ratio = 4 / 3;

const anchors = [
	[-1, -1],
	[1, -1],
	[1, 1],
	[-1, 1],
	[0, -1],
	[1, 0],
	[0, 1],
	[-1, 0],
];

const circle = Math.PI * 2;

export function initGrid(canvas: HTMLCanvasElement) {

	let projection = true;

	const ctx = canvas.getContext('2d')!;
	const background = document.createElement('img');
	
	let rect: DOMRect;
	let dpr = 0, cw = 0, ch = 0, cx0 = 0, cy0 = 0;
	let halfW = 0, halfH = 0, tileW = 0, tileH = 0;
	let lineWidth = 0;
	let changed = true;
	
	let thCX = 0, thCY = 0;
	let thX = thCX - thSize / 2, thY = thCY - thSize / 2;

	let projectX: (x: number, y: number) => number;
	let projectY: (x: number, y: number) => number;

	let bgUrl: string | null = null;
	let bgX = -0.032, bgY = 0.0169;
	let bgDrawW = 0, bgDrawH = 0, bgDrawX = 0, bgDrawY = 0;
	let bgZoom = 1.089;

	let pointerDown = false;
	let moveBg = false;

	type Point = {
		x: number;
		y: number;
		angle: number;
		label: string;
	};
	
	let rays: ({
		seq: number;
		a: Point;
		b: Point;
	})[];
	let rayCount = anchors.length;
	let points: Point[] = [];
	
	const sortAngles = (a: number, b: number) => a - b;
	const diffAngles = (a: number, b: number) => {
		const d = Math.abs(a - b) % circle;
		return d > Math.PI ? circle - d : d;
	};
	
	const castRays = () => {
	
		const thSX = Math.abs(thCX) > 3 ? Math.sign(thCX) : 0;
		const thSY = Math.abs(thCY) > 3 ? Math.sign(thCY) : 0;

		rays = [];
		for (const [seq, [xs, ys]] of anchors.entries()) {
		
			const label = String.fromCharCode(65 + seq);
			
			const x = borderAt * xs, y = borderAt * ys;
			const angle = Math.atan2(x - thCX, thCY - y);
			
			const xD = thCX - x, yD = thCY - y;
			const xA = Math.abs(xD), yA = Math.abs(yD);
			
			if ((thSX || thSY) && (!thSX || thSX == xs) && (!thSY || thSY == ys)) continue;
			
			let scale = borderRange / (xs ? ys ? Math.max(xA, yA) : xA : yA);
			if (Math.abs(y + yD * scale) > borderAt) scale *= borderAt / Math.abs(y + yD * scale);
			if (Math.abs(x + xD * scale) > borderAt) scale *= borderAt / Math.abs(x + xD * scale);
			
			const bX = x + xD * scale, bY = y + yD * scale;
			const b: Point = {
				x: bX,
				y: bY,
				angle: Math.atan2(bX - thCX, thCY - bY),
				label: `${label}o`,
			};

			if (rays.some(r => diffAngles(angle, r.b.angle) < Math.PI * 0.2)) {
				continue;
			}
			
			rays.push({
				seq,
				a: { x, y, angle, label },
				b,
			});
			
		}
		
		rayCount = rays.length;
		rays.sort((a, b) => sortAngles(a.a.angle, b.a.angle));

		points = [
			...rays.map(r => r.a),
			...rays.map(r => r.b),
		];
		points.sort((a, b) => sortAngles(a.angle, b.angle));
		
		changed = true;
		
	};
	castRays();

	const setUp = () => {

		halfH = (ch / (gridSize + 2) / 2);
		
		if (projection) {
		
			halfW = (halfH * ratio);
			projectX = (x, y) => cx0 + x * halfW - y * halfW;
			projectY = (x, y) => cy0 + x * halfH + y * halfH;

			tileW = halfW * 2;
			tileH = halfH * 2;

		}
		else {
		
			halfW = halfH;
			
			projectX = (x, _y) => cx0 + x * tileW;
			projectY = (_x, y) => cy0 + y * tileH;
			
			tileW = halfW * 2;
			tileH = halfH * 2;

		}

		cx0 = ((cw - tileW * gridSize) / 2) + gridHalf * tileW;
		cy0 = ((ch - tileH * gridSize) / 2) + gridHalf * tileH;
		
		scaleBg();
		
		changed = true;
		
	};
	
	const scaleBg = () => {
		bgDrawH = (ch * bgZoom);
		bgDrawW = ((background.naturalWidth / background.naturalHeight) * bgDrawH);
		bgDrawX = (cx0 + bgX * ch - bgDrawW / 2);
		bgDrawY = (cy0 + bgY * ch - bgDrawH / 2);
		changed = true;
	};
	
	const updateSize = () => {
		
		dpr = devicePixelRatio;
		
		rect = canvas.getBoundingClientRect();
		canvas.width = cw = Math.round(rect.width * dpr);
		canvas.height = ch = Math.round(rect.height * dpr);
		console.log(cw / ch);

		lineWidth = (2 * dpr);

		if (background.naturalWidth) scaleBg();
		
		setUp();
		
	};

	const moveTo = (x: number, y: number) => ctx.moveTo(projectX(x, y), projectY(x, y));
	const lineTo = (x: number, y: number) => ctx.lineTo(projectX(x, y), projectY(x, y));
	
	const drawRect = (x: number, y: number, w: number, h: number) => {
		ctx.beginPath();
		moveTo(x, y);
		lineTo(x + w, y);
		lineTo(x + w, y + h);
		lineTo(x, y + h);
		lineTo(x, y);
		ctx.closePath();
	};
	
	updateSize();
	window.addEventListener('resize', updateSize);
	
	const drawGrid = () => {

		ctx.lineWidth = lineWidth;

		ctx.globalAlpha = pointerDown && moveBg ? 1 : 0.5;
		ctx.strokeStyle = '#999';
		for (const dist of [gridHalf, borderAt]) {
			ctx.beginPath();
			moveTo(-dist, -dist);
			lineTo(dist, -dist);
			lineTo(dist, dist);
			lineTo(-dist, dist);
			lineTo(-dist, -dist);
			ctx.strokeStyle = 'white';
			ctx.stroke();
			ctx.strokeStyle = 'black';
			ctx.setLineDash([lineWidth * 3, lineWidth * 3]);
			ctx.stroke();
			ctx.setLineDash([]);
		}
		
		ctx.globalAlpha = 0.1;
		ctx.strokeStyle = '#999';
		
		for (let x = -gridHalf; x <= gridHalf; x++) {
			ctx.beginPath();
			moveTo(x, -gridHalf);
			lineTo(x, gridHalf);
			ctx.stroke();
		}
		for (let y = -gridHalf; y <= gridHalf; y++) {
			ctx.beginPath();
			moveTo(-gridHalf, y);
			lineTo(gridHalf, y);
			ctx.stroke();
		}
		ctx.globalAlpha = 1;
		
	};

	const drawPoints = () => {
		ctx.strokeStyle = '#999';
		ctx.fillStyle = '#999';
		ctx.font = '20px sans-serif';
		for (const ray of rays) {

			const { x, y } = ray.a;

			ctx.beginPath();
			ctx.ellipse(projectX(x, y), projectY(x, y), halfW * 0.5, halfH * 0.5, 0, 0, Math.PI * 2);
			ctx.closePath();
			
			ctx.fill();

			// ctx.fillText(label, projectX(x, y) + 10, projectY(x, y) - 10);
			
		}
	};

	const drawTriangles = () => {
		for (let i = 0; i < points.length; i++) {

			const a = points[i];
			const b = points[(i + 1) % points.length];
			
			const hue = a.angle + (b.angle > a.angle ? b.angle - a.angle : b.angle + circle - a.angle);
			ctx.fillStyle = `rgb(${toRGB([hue / Math.PI * 360, 100, 44])})`;
			
			ctx.beginPath();
			moveTo(thCX, thCY);
			lineTo(a.x, a.y);
			if (a.x != b.x && a.y != b.y) lineTo(
				Math.abs(a.x) > Math.abs(b.x) ? a.x : b.x,
				Math.abs(a.y) > Math.abs(b.y) ? a.y : b.y,
			);
			lineTo(b.x, b.y);

			ctx.closePath();
			ctx.globalAlpha = 0.3;
			ctx.fill();
			ctx.globalAlpha = 1;

		}
	};

	const drawRays = () => {
		ctx.globalAlpha = 1;
		ctx.strokeStyle = 'black';
		for (let i = 0; i < rays.length; i++) {
			const ray = rays[i];
			ctx.beginPath();
			moveTo(ray.a.x, ray.a.y);
			lineTo(ray.b.x, ray.b.y);
			ctx.closePath();
			ctx.stroke();
		}
	};
	
	const animate = () => {
	
		requestAnimationFrame(animate);
		
		if (!changed) return;
		changed = false;

		console.log(bgX, bgY, bgZoom);

		ctx.clearRect(0, 0, cw, ch);

		if (background.naturalWidth && projection) ctx.drawImage(background, bgDrawX, bgDrawY, bgDrawW, bgDrawH);
		
		drawTriangles();
		drawGrid();
		drawPoints();
		drawRays();
		
		ctx.strokeStyle = '#000';
		ctx.fillStyle = 'white';
		drawRect(thX, thY, thSize, thSize);
		ctx.stroke();
		ctx.globalAlpha = 0.5;
		ctx.fill();
		ctx.globalAlpha = 1;
		
		
	};
	animate();
	
	const snap = (pt: number) => {
		return Math.max(-maxThOffset, Math.min(maxThOffset, pt));
	};
	
	let dragX = 0, dragY = 0;
	const drag = (ox: number, oy: number) => {
	
		if (moveBg) {
			bgX += (ox - dragX) / ch;
			bgY += (oy - dragY) / ch;
			dragX = ox;
			dragY = oy;
			scaleBg();
			return;
		}
	
		const fx = ox * dpr - cx0, fy = oy * dpr - cy0;
		if (projection) {
			const pX = (fx / halfW + fy / halfH) / 2;
			const pY = (fy / halfH - fx / halfW) / 2;
			thCX = snap(pX);
			thCY = snap(pY);
		}
		else {
			thCX = snap(fx / tileW);
			thCY = snap(fy / tileH);
		}
		
		thX = thCX - thHalf;
		thY = thCY - thHalf;
		castRays();
		changed = true;
		
	};
	
	canvas.addEventListener('pointerdown', ev => {
	
		if (ev.button) return;
	
		pointerDown = true;
		canvas.setPointerCapture(ev.pointerId);
		
		const ox = ev.offsetX, oy = ev.offsetY;
		dragX = ox;
		dragY = oy;
		
		const fx = ox * dpr - cx0, fy = oy * dpr - cy0;
		const pX = (fx / halfW + fy / halfH) / 2;
		const pY = (fy / halfH - fx / halfW) / 2;
		
		moveBg = Math.abs(pX) > maxThOffset || Math.abs(pY) > maxThOffset;
		
		drag(ox, oy);
		
	});
	canvas.addEventListener('pointermove', ev => {
		if (!pointerDown) return;
		drag(ev.offsetX, ev.offsetY);
	});
	canvas.addEventListener('pointerup', () => {
		pointerDown = false;
		thCX = Math.round(thCX);
		thCY = Math.round(thCY);
		thX = thCX - thHalf;
		thY = thCY - thHalf;
		castRays();
		changed = true;
	});
	
	canvas.addEventListener('wheel', e => {
		bgZoom += 0.01 * Math.sign(e.deltaY);
		scaleBg();
	});
	document.getElementById('zoomIn')!.addEventListener('click', () => {
		bgZoom += 0.01;
		scaleBg();
	});
	document.getElementById('zoomOut')!.addEventListener('click', () => {
		bgZoom -= 0.01;
		scaleBg();
	});

	const projBox = document.getElementById('projection') as HTMLInputElement;
	projBox.addEventListener('change', () => {
		projection = projBox.checked;
		setUp();
	});
	projection = projBox.checked;
	setUp();

	const fileInput = document.getElementById('upload') as HTMLInputElement;
	fileInput.addEventListener('change', () => {

		const file = fileInput.files?.[0];
		if (!file) return;

		if (bgUrl) URL.revokeObjectURL(bgUrl);

		bgUrl = URL.createObjectURL(file);
		background.src = bgUrl;

	});

	background.addEventListener('load', () => {
		updateSize();
	});
	
	background.src = './base.jpg';
	
}

function toRGB(hsl: ArrayLike<number>, out?: number[]) {
	const h = hsl[0] / 360;
	const s = hsl[1] / 100;
	const l = hsl[2] / 100;

	const rgb = out || [0, 0, 0];
	if (s === 0) {
		const val = Math.round(l * 255);
		rgb[0] = val;
		rgb[1] = val;
		rgb[2] = val;
		return rgb;
	}

	const t2 = l < 0.5 ? l * (1 + s) : l + s - l * s;
	const t1 = 2 * l - t2;

	for (let i = 0; i < 3; i++) {
		let t3 = h + 1 / 3 * -(i - 1);
		if (t3 < 0) {
			t3++;
		}
		else if (t3 > 1) {
			t3--;
		}

		let val: number;
		if (6 * t3 < 1) {
			val = t1 + (t2 - t1) * 6 * t3;
		}
		else if (2 * t3 < 1) {
			val = t2;
		}
		else if (3 * t3 < 2) {
			val = t1 + (t2 - t1) * (2 / 3 - t3) * 6;
		}
		else {
			val = t1;
		}

		rgb[i] = Math.round(val * 255);
	}

	return rgb;
}

initGrid(document.getElementById('canvas') as HTMLCanvasElement);
